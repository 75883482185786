<template>
  <div class="program-page">
    <transition name="component-fade" mode="out-in">
      <div key="loader" v-if="loading" class="f-center h-100">
        <div>
          <Loader />
        </div>
      </div>
      <div v-else key="programPage" class="program-page__wrapper">
        <div
          v-if="!loading"
          :style="{ 'background-image': backgroundImage }"
          class="program-page__program-info program-info"
        >
          <div class="program-info__wrapper container">
            <div class="program-info__info">
              <div>
                <div class="program-info__subtitle">{{ $t('header.program') }}</div>
                <div class="program-info__title">
                  <TextareaCustom
                    :value="title"
                    :lineHeight="40"
                    :max-height="120"
                    :placeholder="$t('placeholders.enterNameOfTheProgram')"
                    @blur="changeProgramName"
                  />
                </div>
                <div class="program-info__description">
                  <TextareaCustom
                    :value="description"
                    :lineHeight="22"
                    :max-height="70"
                    :placeholder="$t('placeholders.fillInTheDescriptionOfTheProgram')"
                    @blur="changeProgramDescription"
                  />
                </div>
              </div>
              <div class="program-info__action">
                <AddButtonWithIcon
                  :title="$t('buttonLabels.createCourse')"
                  class="course-dropdown"
                  v-if="courses.length > 14"
                  :isDisabled="true"
                  @click="createActivityType"
                />
                <div v-else class="course-dropdown">
                  <b-dropdown
                    size="lg"
                    dropright
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template #button-content>
                      <AddButtonWithIcon :title="$t('buttonLabels.createCourse')" />
                    </template>

                    <b-dropdown-item href="#" @click.prevent="createActivityType"
                      >{{ $t('buttonLabels.createNew') }}
                    </b-dropdown-item>
                    <b-dropdown-item v-b-modal.modal-courses-list href="#">
                      {{ $t('buttonLabels.addFromCreated') }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <AddButton
                  variant="secondary"
                  :title="$t('buttonLabels.statistics')"
                  @click="showStatistic = !showStatistic"
                />
                <div class="dot-button">
                  <b-dropdown
                    size="lg"
                    dropright
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template #button-content>
                      <img src="@/assets/images/svg/properties-icon.svg" alt="Удалить" />
                    </template>
                    <b-dropdown-item
                      v-if="!isManagerRole"
                      href="#"
                      @click.prevent="showModalInviteAdmin"
                    >
                      {{ $t('buttonLabels.inviteAdmin') }}
                    </b-dropdown-item>
                    <b-dropdown-item href="#" @click.prevent="showModalInviteStudent">
                      {{ $t('buttonLabels.inviteStudents') }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="!isManagerRole"
                      href="#"
                      @click.prevent="showModalRemove()"
                      >{{ $t('buttonLabels.deleteProgram') }}
                    </b-dropdown-item>
                    <b-dropdown-item href="#" @click.prevent="openCertificatePage">
                      {{ $t('buttonLabels.createCertificate') }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
            <div class="program-info__bg-icon">
              <CoverChange :meta.sync="meta" />
            </div>
            <div class="program-info__sequence-step">
              <SequencedStepSwitcher
                :label="$t('supportText.studentsGetAccessSequencedly')"
                :value="isBlocking"
                @click.native.prevent="toggleHandler"
              />
            </div>
          </div>
        </div>

        <div class="program-page__courses-list">
          <div class="container">
            <transition name="component-fade" mode="out-in">
              <div key="content" v-if="courses.length" class="content">
                <ContentList
                  class="w-100"
                  :list="courses"
                  :disableDragAndDrop="isDisabledDragAndDrop"
                  :routerPush="routerPush"
                  :isManagerRole="isManagerRole"
                  @changeOrder="changeOrder"
                  @remove="showModalRemove"
                  @remove-from-program="showModalRemoveFromProgram"
                />
              </div>
              <div key="empty-state" v-else class="empty-state">
                <div class="empty-state__title">
                  {{ $t('supportText.createFirstCourse') }}
                </div>

                <div class="image_wrapper">
                  <div class="image">
                    <img src="@/assets/images/svg/computer.svg" alt="Компьютер" />
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>

        <ModalCoursesList :programCoursesList="courses" @refresh-courses="refreshCourses" />
        <InviteModal :inviteLink="inviteLink" :inviteType="inviteType" />

        <Statistic
          class="program-page__statistic"
          :class="{ 'slide-up': showStatistic }"
          :showStatistic.sync="showStatistic"
          :statisticTitle="title"
          :managerBanList="managerBanList"
          :showModalInviteAdmin="showModalInviteAdmin"
          :isManagerRole="isManagerRole"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TextareaCustom from '@/components/TextAreaCustom.vue';
// eslint-disable-next-line import/no-cycle
import ActivityService from '@/services/activity.service';
import ContentList from '@/components/ContentList.vue';
import ModalCoursesList from '@/components/Modals/ModalCoursesList.vue';
import InviteModal from '@/components/Modals/InviteModal.vue';
import AddButton from '@/components/Buttons/AddButton.vue';
import AddButtonWithIcon from '@/components/Buttons/AddButtonWithIcon.vue';
import Loader from '@/components/Loader/Loader.vue';
import Statistic from '@/components/Statistics/Statistic.vue';
import CoverChange from '@/components/CoverChange.vue';
import deviceMixin from '@/mixins/device.mixin';
import SequencedStepSwitcher from '@/components/SequencedStepSwitcher.vue';

export default {
  name: 'Program',
  components: {
    SequencedStepSwitcher,
    CoverChange,
    Statistic,
    Loader,
    ModalCoursesList,
    TextareaCustom,
    ContentList,
    InviteModal,
    AddButton,
    AddButtonWithIcon,
  },
  mixins: [deviceMixin],
  data() {
    return {
      title: '',
      description: '',
      meta: null,
      courses: [],
      loading: true,
      isDisabledDragAndDrop: false,
      programId: this.$route.params.programId,
      showStatistic: false,
      inviteType: 'student',
      programToken: '',
      subscribeToken: '',
      managerBanList: [],
      isManagerRole: false,
      isBlocking: false,
    };
  },
  async created() {
    await ActivityService.getActivityById(this.programId)
      .then(({ data }) => {
        this.title = data.name;
        this.description = data.description;
        this.meta = data.meta;
        this.managerBanList = data.manager_ids_ban_list;

        const managerIds = data.manager_ids;
        // eslint-disable-next-line max-len
        this.isManagerRole = managerIds.includes(this.user.id) && !this.managerBanList.includes(this.user.id);
        this.isBlocking = !!data.is_blocking;
      })
      .catch(console.log);

    await ActivityService.getActivitiesByParentId(this.programId)
      .then(({ data }) => {
        this.courses = data ?? [];
      })
      .catch(console.log);

    this.loading = false;
  },
  methods: {
    ...mapActions('toaster', ['setToaster']),
    createActivityType() {
      if (this.courses.length > 14) {
        return this.setToaster({
          type: 'toast-warning',
          toast: {
            title: this.$t('supportText.youCannotCreateMore'),
            body: this.$t('supportText.exceededLimit'),
          },
        });
      }
      ActivityService.createActivityByType({
        type: 'course',
        id: this.programId,
      })
        .then(({ data: courseId }) => this.$router.push(`/course/${this.programId}/${courseId}`))
        .catch(console.log);
    },
    async changeOrder(courses) {
      const oldCoursesOrder = this.courses;
      const oldIds = this.courses.reduce((previousValue, currentValue) => {
        previousValue.push(currentValue._id);
        return previousValue;
      }, []);
      const newIds = courses.reduce((previousValue, currentValue) => {
        previousValue.push(currentValue._id);
        return previousValue;
      }, []);

      this.courses = courses;
      this.isDisabledDragAndDrop = true;
      ActivityService.changeOrder({
        programId: this.programId,
        id: this.programId,
        oldIds,
        newIds,
      })
        .catch(() => (this.courses = oldCoursesOrder))
        .finally(() => (this.isDisabledDragAndDrop = false));
    },
    routerPush(item) {
      this.$router.push(`/course/${this.programId}/${item._id}`);
    },
    showModalRemove(id) {
      let modalText = this.$t('supportText.thisActionCannotBeUndone');
      let removeType = 'course';
      let _id = id;
      if (!_id) {
        modalText = this.$t('supportText.removeProgram');
        removeType = 'program';
        _id = this.programId;
      }

      this.$bvModal
        .msgBoxConfirm(modalText, {
          size: 'md',
          okVariant: 'secondary',
          cancelVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'lms-modal__footer border-top-0',
          centered: true,
          bodyClass: 'lms-modal__body',
          okTitle: this.$t('buttonLabels.delete'),
          cancelTitle: this.$t('buttonLabels.cancel'),
        })
        .then((isConfirmed) => ({
          isConfirmed,
          id: _id,
          removeType,
        }))
        .then(this.remove)
        .catch(console.log);
    },
    showModalRemoveFromProgram(id) {
      const modalText = this.$t('supportText.actionCannotBeUndone');
      let removeType = 'course';
      let _id = id;
      if (!_id) {
        removeType = 'program';
        _id = this.programId;
      }

      this.$bvModal
        .msgBoxConfirm(modalText, {
          size: 'md',
          okVariant: 'secondary',
          cancelVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'lms-modal__footer border-top-0',
          centered: true,
          bodyClass: 'lms-modal__body',
          okTitle: this.$t('buttonLabels.delete'),
          cancelTitle: this.$t('buttonLabels.cancel'),
        })
        .then((isConfirmed) => ({
          isConfirmed,
          id: _id,
          removeType,
        }))
        .then(this.removeFromProgram)
        .catch(console.log);
    },
    showModalInviteStudent() {
      // validate tariff
      if (this.userTariff.name === 'label.tariffFree') {
        return this.setToaster({
          type: 'toast-warning',
          toast: {
            title: this.$t('errorMessages.chooseTariff'),
            body: this.$t('supportText.selectTariffToInviteStudents'),
            button: {
              action: () => this.$router.push({ name: 'ManageTariffs' }, () => {}),
              text: this.$t('label.subscriptionManagement'),
            },
          },
        });
      }
      ActivityService.getProgramSubscribeToken(this.programId)
        .then(({ data: { token } }) => {
          this.subscribeToken = token;
          this.inviteType = 'student';
          this.$bvModal.show('invite-modal');
        })
        .catch(console.log);
    },
    showModalInviteAdmin() {
      if (!this.isManagerRole) {
        ActivityService.getProgramToken(this.programId)
          .then(({ data: { token } }) => {
            this.programToken = token;
            this.inviteType = 'admin';
            this.$bvModal.show('invite-modal');
          })
          .catch(console.log);
      }
    },
    openCertificatePage() {
      this.$router.push({
        name: 'CertificateConstructor',
        params: {
          programId: this.programId,
          courseId: 0,
        },
      });
    },
    remove({ isConfirmed, id, removeType }) {
      if (!isConfirmed) {
        return;
      }
      ActivityService.deleteActivity(id)
        .then(() => {
          if (removeType === 'course') {
            this.courses = this.courses.filter((course) => course._id !== id);
          } else {
            this.$router.push(`/programs?page=${this.$route.params.page ?? 1}`);
          }
        })
        .catch(console.log);
    },
    async changeProgramName(name) {
      if (name !== this.title && name) {
        const previousTitle = this.title;
        this.title = name;
        try {
          await ActivityService.updateActivity(this.programId, { name });
        } catch (error) {
          this.title = previousTitle;
          console.log(error);
        }
      }
    },
    async changeProgramDescription(description) {
      if (description !== this.description) {
        const previousDescription = this.description;
        this.description = description;
        try {
          await ActivityService.updateActivity(this.programId, { description });
        } catch (error) {
          this.description = previousDescription;
          console.log(error);
        }
      }
    },
    async refreshCourses() {
      await ActivityService.getActivitiesByParentId(this.programId)
        .then(({ data }) => {
          this.courses = data ?? [];
        })
        .catch(console.log);
    },
    async removeFromProgram({ isConfirmed, id }) {
      try {
        if (!isConfirmed) return;

        await ActivityService.removeActivityParent({
          programId: this.programId,
          id,
          parentId: this.programId,
        });

        this.courses = this.courses.filter((c) => c._id !== id);
      } catch (error) {
        console.log(error);
      }
    },
    changeBlockingMode(bool) {
      this.isBlocking = bool;
      if (bool) {
        ActivityService.blockOrderOfActivity(this.programId);
      } else {
        ActivityService.unblockOrderOfActivity(this.programId);
      }
    },
    toggleHandler() {
      let text = this.$t('supportText.unlockCourses');
      if (!this.isBlocking) {
        text = this.$t('supportText.lockCourses');
      }

      this.$bvModal
        .msgBoxConfirm(text, {
          size: 'sm',
          okVariant: 'secondary',
          cancelVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'lms-modal__footer border-top-0',
          centered: true,
          bodyClass: 'lms-modal__body',
          okTitle: this.$t('buttonLabels.yesEnable'),
          cancelTitle: this.$t('buttonLabels.cancel'),
        })
        .then((bool) => {
          if (bool) {
            this.changeBlockingMode(!this.isBlocking);
          }
        })
        .catch(console.log);
    },
  },
  computed: {
    ...mapGetters({ userTariff: 'auth/getUserTariff', user: 'auth/getUser' }),
    backgroundImage() {
      let bg = require('@/assets/images/png/program-background.png');
      if (this.meta?.imageHighres) {
        bg = `https://${
          // eslint-disable-next-line no-nested-ternary
          this.$device.isDesktop
            ? this.meta.imageHighres
            : this.$device.isTablet
              ? this.meta.imageMidres
              : this.meta.imageLowres
        }`;
      }

      const overlay = 'linear-gradient(90deg, #72aaf41c 53.42%, #72aaf400 81.22%), linear-gradient(90deg, #121212 51.11%, #12121200 81.26%)';
      return `${overlay}, url("${bg}")`;
    },
    inviteLink() {
      return this.inviteType === 'student'
        ? `${window.location.origin}/subscribe/${this.programId}?token=${this.subscribeToken}`
        : `${window.location.origin}/subscribe-manager/${this.programId}?token=${this.programToken}`;
    },
  },
  watch: {
    showStatistic: {
      handler(bool) {
        const showStatistic = () => {
          const mainLayout = document.getElementById('mainLayout');
          mainLayout.scrollTop = 0;
          document.getElementById('mainLayout').classList.add('scrollHidden');
        };
        // eslint-disable-next-line no-unused-expressions
        bool
          ? setTimeout(showStatistic, 600)
          : document.getElementById('mainLayout').classList.remove('scrollHidden');
      },
    },
  },
};
</script>
